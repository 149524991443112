:root {
	--responsive-logo-height: 46px;
	--responsive-corporate-menu-height: 72px;
}

@media (max-width: 700px) {
	:root {
		/*
			At the approximate screen width where the CorporateMenuItems start to overlap the logo
			Hide the menu items, and make the bar slightly less tall
		*/
		--responsive-logo-height: 30px;
		--responsive-corporate-menu-height: 48px;
	}
}

style {
	display: none !important;
}
html {
	color: var(--color-grey-800);
	height: 100%;
	overflow: hidden;
}
body {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

/*
	Avoid a bug caused by Cookiebot's injecting elements into DOM at random.
	To fix, display: none all <div> directly inside <body> (the cookiebot elements do not have an ID
	or class) and then undo that for the elements we endorse.
*/
body > div {
	display: none;
}
#root,
#CybotCookiebotDialog {
	display: block;
}
